// Registration
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

// Set user Data
export const SET_USER_DATA = 'SET_USER_DATA';

// Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

// Logout
export const LOGOUT_USER = 'LOGOUT_USER';
