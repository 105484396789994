// import packages
import React from 'react';
import { Link } from 'react-router-dom';

// import local dependencies
import BreadcrumbsHome from '../Layout/BreadcrumbsHome';
import Footer from '../Layout/Footer';
import chatbotinterface2 from '../Layout/images/Chatbot-Interface2.png';
import Sara_home from '../Layout/images/Sara_home1.png';

// import material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

// styling
const styles = (theme) => {
  return {
    root: {
      link: {
        display: 'flex',
      },
      icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
      },
      flexGrow: 1,
      container: {
        backgroundColor: '',
        padding: '20px',

        margin: '40px 0px 0px 0px',
      },
      gridLeft: {
        backgroundColor: '',
        height: 500,
      },
      gridRight: {
        backgroundColor: '',
        textAlign: 'center',
      },
      titleTypography: {
        fontFamily: 'Montserrat',
        marginBottom: '20px',
        color: 'white',
      },
      subtitleTypography: {
        fontFamily: 'Montserrat',
        marginBottom: '20px',
      },
    },
    img: {
      maxHeight: 400,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%',
    },
    img2: {
      maxHeight: 200,
      display: 'block',
      maxWidth: 200,
      overflow: 'hidden',
      width: '100%',
    },
    titleButton: {
      fontFamily: 'Montserrat',
      marginBottom: '20px',
      color: 'white',
      backgroundColor: '#4a148c',
    },
  };
};

const Home = (props) => {
  // destructuring: styling props
  const {
    root,
    container,
    gridLeft,
    gridRight,
    titleTypography,
    subtitleTypography,
    titleButton,
    img,
    img2,
  } = props.classes;

  // react: return jsx
  return (
    <div>
      <br />
      <Grid container>
        <Grid item xs={1} className={gridLeft}></Grid>
        <Grid item xs={3} className={gridLeft}>
          <img className={img} src={Sara_home} alt={'Walk-through wizard'} />
        </Grid>
      </Grid>
      <Container maxWidth='lg'>
        <div className={root}>
          <br />
          <br />
          <br />
          <Grid container spacing={2} className={container}>
            <Grid item xs={6} className={gridLeft}>
              <Typography
                variant='h3'
                className={titleTypography}
                style={{ display: 'inline-block' }}
              >
                Help your{' '}
              </Typography>{' '}
              <Typography
                style={{ display: 'inline-block' }}
                variant='subtitle1'
                className={titleTypography}
              >
                students{' '}
              </Typography>
              {'  '}
              <Typography
                style={{ display: 'inline-block' }}
                variant='subtitle1'
                className={titleTypography}
              >
                learn{' '}
              </Typography>{' '}
              <Typography
                style={{ display: 'inline-block' }}
                variant='subtitle1'
                className={titleTypography}
              >
                better{' '}
              </Typography>{' '}
              <Typography
                style={{ display: 'inline-block' }}
                variant='h3'
                className={titleTypography}
              >
                by creating awesome chatbots
              </Typography>
              <Typography variant='h4' className={subtitleTypography}>
                Chatbots offer students individual support whenever students
                need.
              </Typography>
              <br />
              <br />
              <Button
                variant='contained'
                size='large'
                className={titleButton}
                component={Link}
                to='/register'
              >
                Get started
              </Button>
            </Grid>
            <Grid item xs={6} className={gridRight}>
              <div>
                <iframe
                  title="youtubevideo"
                  width="700"
                  height="394"
                  src="https://www.youtube.com/embed/zRKpq3KY3gA"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Divider />
          <br />
          <br />
          <Box textAlign='center'>
            <Typography variant='h4' className={subtitleTypography}>
              Create your own Chatbot with three simple steps.
            </Typography>
          </Box>
          <br />
          <br />
          <br />
          <br />
          <BreadcrumbsHome />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Divider />
          <Typography
            component='div'
            style={{ backgroundColor: '#eeeeee', height: '60vh' }}
          >
            <br />
            <br />
            <Grid container spacing={1} className={container}>
              <Grid item xs={3} />
              <Grid item xs={6} container justify='center'>
                <Box textAlign='center'>
                  <Typography variant='h4' className={subtitleTypography}>
                    Try me!
                  </Typography>
                  <Typography variant='h6' className={subtitleTypography}>
                    Want to learn more about CreatemyBot, the chatbot platform?{' '}
                    <br />
                    Go try it yourself!
                  </Typography>
                  <br />
                  <br />
                  <Typography variant='h6' className={subtitleTypography}>
                    Hier wird einmal eine Chatbot-Demo angezeigt werden!
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} className={gridLeft}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <img
                  className={img2}
                  src={chatbotinterface2}
                  alt={'chatbotinterface2'}
                />
              </Grid>
            </Grid>
          </Typography>
          <Divider />
          <Footer />
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Home);
