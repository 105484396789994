// import packages
import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';

// import local dependenceis
import Logo from './images/tubot_logo.png';
import AuthContext from '../../Context/Auth/authContext';

// import material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// styling
const styles = (theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      fontFamily: 'shadowsintolight',
      textDecoration: 'none',
      color: 'black',
    },
    accountCircle: {
      marginRight: '30px',
      color: theme.palette.info.dark,
    },
    dashboardButton: {
      fontFamily: 'Montserrat',
      marginRight: '20px',
      color: 'black',
    },
    createnewButton: {
      fontFamily: 'Montserrat',
      marginRight: '20px',
      color: 'white',
      backgroundColor: theme.palette.info.dark,
    },
    signupbutton: {
      fontFamily: 'Montserrat',
      marginRight: '20px',
      color: 'white',
      backgroundColor: theme.palette.info.dark,
    },
    img: {
      height: 40,
      display: 'block',
      maxWidth: 40,
      overflow: 'hidden',
      width: '100%',
    },
    icon: {
      '& > span': {
        margin: theme.spacing(2),
      },
    },
  };
};

const Navbar = (props) => {
  // destructuring: styling props
  const {
    root,
    title,
    accountCircle,
    dashboardButton,
    img,
    signupbutton,
    createnewButton,
  } = props.classes;

  // global state: get access to authentication context
  const authContext = useContext(AuthContext);
  const { isAuthenticated, logoutUser } = authContext;

  // function 1:
  const onClickButtonHandler = () => {
    logoutUser();
  };

  // react: return jsx
  return (
    <div className={root}>
      <AppBar position='static'>
        <Toolbar>
          <img className={img} src={Logo} alt={Logo} />
          <Typography variant='h4' className={title} component={Link} to='/'>
            MyTeachingBot
          </Typography>
          {isAuthenticated === true ? (
            <Fragment>
              <Button
                className={dashboardButton}
                color='inherit'
                component={Link}
                to='/dashboard'
              >
                Dashboard
              </Button>
              <Button
                className={createnewButton}
                component={Link}
                endIcon={<AddCircleOutlineIcon />}
                to='/createnew'
              >
                Create new
              </Button>
              <Button
                className={dashboardButton}
                component={Link}
                to='/'
                onClick={onClickButtonHandler}
              >
                Logout
              </Button>
              <Button justifycontent='center' component={Link} to='/dashboard'>
                <AccountCircle className={accountCircle} />
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                color='inherit'
                className={dashboardButton}
                component={Link}
                to='/login'
              >
                How it works
              </Button>
              <Button
                color='inherit'
                className={dashboardButton}
                component={Link}
                to='/login'
              >
                About us
              </Button>
              <Button
                variant='contained'
                className={signupbutton}
                component={Link}
                to='/login'
              >
                Sign in
              </Button>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withStyles(styles)(Navbar);
