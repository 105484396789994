// import packages
import React from 'react';
import { Link } from 'react-router-dom';

// import local dependencies
import Breadcrumbs from '../Layout/Breadcrumbs.js';
import Sara_moreInformation from '../Layout/images/Sara_moreinformation.png';

// import material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';

// styling
const styles = (theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    container: {
      backgroundColor: '',
      padding: '10px',
      margin: '10px 0px 0px 0px',
    },
    horizontalTitleGrid: {
      backgroundColor: '',
      textAlign: 'center',
    },
    quarterBodyGrid: {
      marginTop: '10px',
    },
    card: {
      padding: '15px',
      textAlign: 'center',
      height: '100%',
      marginBottom: '15px',
    },
    cardinactive: {
      padding: '15px',
      textAlign: 'center',
      marginBottom: '15px',
      height: '100%',
      backgroundColor: theme.palette.primary.dark,
    },
    cardTitle: {
      fontFamily: 'Montserrat',
      marginBottom: '20px',
    },
    subtitleTypography: {
      fontFamily: 'Montserrat',
      marginBottom: '20px',
    },
    titleButton: {
      fontFamily: 'Montserrat',
      marginBottom: '5px',
      color: 'white',
      backgroundColor: '#4a148c',
      width: '100%',
    },
    moreinformationlink: {
      fontFamily: 'Montserrat',
      color: theme.palette.primary.dark,
    },
    gridLeft: {
      backgroundColor: '',
      height: 50,
    },
    img: {
      maxHeight: 400,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%',
    },
    cardBody: {},
  };
};

const Createnew = (props) => {
  // destructuring: styling props
  const {
    root,
    container,
    horizontalTitleGrid,
    quarterBodyGrid,
    card,
    cardTitle,
    cardBody,
    titleButton,
    subtitleTypography,
    cardinactive,
    moreinformationlink,
    gridLeft,
    img,
  } = props.classes;

  // react: return jsx
  return (
    <div>
      <br />
      <Grid container>
        <Grid item xs={1} className={gridLeft}></Grid>
        <Grid item xs={3} className={gridLeft}>
          <img
            className={img}
            src={Sara_moreInformation}
            alt={'moreInformation'}
          />
        </Grid>
      </Grid>
      <Container maxWidth='lg'>
        <div className={root}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Grid container spacing={4} className={container}>
            <Grid item xs={12} className={horizontalTitleGrid}>
              <Typography className={subtitleTypography} variant='h4'>
                What kind of Chatbot do you want to create?
              </Typography>
            </Grid>
            <Grid item xs={3} className={quarterBodyGrid}>
              <Card className={card} raised>
                <CardContent>
                  <Typography variant='h5' className={cardTitle}>
                    Quizbot
                  </Typography>
                  <Typography className={cardBody}>
                    Create a simple quizbot that asks students questions and
                    provides feedback on their answers!
                  </Typography>
                  <br />
                  <Typography className={moreinformationlink}>
                    <Link to='/moreinformationquizbot'>more information</Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant='contained'
                    className={titleButton}
                    component={Link}
                    to='/choose'
                  >
                    Get started
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={3} className={quarterBodyGrid}>
              <Card className={cardinactive} raised>
                <CardContent>
                  <Typography variant='h5' className={cardTitle}>
                    Tutor
                  </Typography>
                  <Typography className={cardBody}>
                    Create a tutor that helps students to learn new topics with
                    the help of an explanatory dialog!
                  </Typography>
                  <br />
                  <Typography className={moreinformationlink}>
                    <Link to='#'>more information</Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size='medium'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disabled
                  >
                    Coming soon...
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={3} className={quarterBodyGrid}>
              <Card className={cardinactive} raised>
                <CardContent>
                  <Typography variant='h5' className={cardTitle}>
                    Method Guide
                  </Typography>
                  <Typography className={cardBody}>
                    Create a simple method guide that helps your students learn
                    applying certain steps! <br />
                  </Typography>
                  <br />
                  <Typography className={moreinformationlink}>
                    <Link to='#'>more information</Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size='medium'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disabled
                  >
                    Coming soon...
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={3} className={quarterBodyGrid}>
              <Card className={cardinactive} raised>
                <CardContent>
                  <Typography variant='h5' className={cardTitle}>
                    Reflector
                  </Typography>
                  <Typography className={cardBody}>
                    Create a simple bot that helps students to reflect on their
                    own learning process!
                    <br />
                    {` `} <br />
                  </Typography>
                  <br />
                  <Typography className={moreinformationlink}>
                    <Link to='#'>more information</Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size='medium'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disabled
                  >
                    Coming soon...
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Breadcrumbs />
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Createnew);
