// theme imports
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import montserrat from '../Components/Layout/fonts/Montserrat-SemiBold.ttf';
import shadowsintolight from '../Components/Layout/fonts/ShadowsIntoLight.ttf';

const shadowsFont = {
  fontFamily: 'shadowsintolight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
  local('shadowsintolight'),
  url(${shadowsintolight})
  `,
};

const montserratFont = {
  fontFamily: 'montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
  local('montserrat'),
  url(${montserrat})
  `,
};

// own m-ui theme
export const teechrTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#fafafa',
      dark: '#e0e0e0',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff176',
      main: '#ffee58',
      dark: '#fdd835',
      contrastText: '#fff',
    },
    info: {
      light: '#7e57c2',
      main: '#673ab7',
      dark: '#5e35b1',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'montserrat',
      '-apple-system',
      'Helvetica',
      'Roboto',
      'sans-serif',
      '"Segoe UI"',
      "'Helvetica Neue'",
      'Arial',
      'shadowsintolight',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [shadowsFont, montserratFont],
      },
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          fill: '#fdd835',
          '& $text': {
            fill: '#212121',
          },
        },
      },
      text: {
        fill: '#212121',
      },
    },
  },
});

teechrTheme.typography.h1 = {
  fontFamily: 'montserrat',
  fontWeight: 300,
  fontSize: '1.6rem',
  '@media (min-width:600px)': {
    fontSize: '1.9rem',
  },
  [teechrTheme.breakpoints.up('md')]: {
    fontSize: '2.8rem',
  },
};

teechrTheme.typography.h2 = {
  fontFamily: 'Montserrat',
  fontWeight: 100,
  fontSize: '1.4rem',
  '@media (min-width:600px)': {
    fontSize: '1.7rem',
  },
  [teechrTheme.breakpoints.up('md')]: {
    fontSize: '2.6rem',
  },
};

teechrTheme.typography.h3 = {
  fontFamily: 'Montserrat',
  fontWeight: 100,
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [teechrTheme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

teechrTheme.typography.h4 = {
  fontFamily: 'Montserrat',
  fontWeight: 100,
  fontSize: '1.0rem',
  '@media (min-width:600px)': {
    fontSize: '1.3rem',
  },
  [teechrTheme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
  },
};

teechrTheme.typography.h5 = {
  fontFamily: 'Montserrat',
  fontWeight: 100,
  fontSize: '1.0rem',
  '@media (min-width:600px)': {
    fontSize: '1.3rem',
  },
  [teechrTheme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
  },
};

teechrTheme.typography.subtitle1 = {
  fontFamily: 'Montserrat',
  fontWeight: 100,
  textDecoration: 'underline yellow',
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [teechrTheme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

export default teechrTheme;
