// import packages
import React, { useContext, useEffect } from 'react';

// import local dependencies
import ChatbotContext from '../../Context/Chatbot/chatbotContext';
import Sara_createnewchatbot from '../Layout/images/Sara_createnewchatbot.png';

// import material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ChatbotTable from '../Layout/ChatbotTable';
import Container from '@material-ui/core/Container';

// styling
const styles = (theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    container: {
      backgroundColor: '',
      padding: '10px',
      margin: '50px 0px 0px 0px',
    },
    horizontalTitleGrid: {
      backgroundColor: '',
      textAlign: 'center',
    },
    quarterBodyGrid: {
      marginTop: '10px',
    },
    card: {
      padding: '15px',
      textAlign: 'center',
      marginBottom: '15px',
    },
    cardinactive: {
      padding: '15px',
      textAlign: 'center',
      marginBottom: '15px',
      backgroundColor: theme.palette.primary.dark,
    },
    cardTitle: {
      fontFamily: 'Montserrat',
      marginBottom: '20px',
    },
    subtitleTypography: {
      fontFamily: 'Montserrat',
      marginBottom: '20px',
    },
    titleButton: {
      fontFamily: 'Montserrat',
      marginBottom: '5px',
      color: 'white',
      backgroundColor: '#4a148c',
      width: '100%',
    },
    gridLeft: {
      backgroundColor: '',
      height: 200,
    },
    img: {
      maxHeight: 400,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%',
    },
    cardBody: {},
  };
};

const Dashboard = (props) => {
  // destructuring: styling props
  const { root, container, horizontalTitleGrid, gridLeft, img } = props.classes;

  // global state: get access to chatbot context
  const chatbotContext = useContext(ChatbotContext);
  const { fetchChatbotFromUser } = chatbotContext;

  // component lifecycle: useEffect hook when component mounts or updates
  useEffect(
    () => {
      fetchChatbotFromUser();
    },
    // eslint-disable-next-line
    []
  );

  // react: return jsx
  return (
    <dev>
      <br />
      <Grid container>
        <Grid item xs={1} className={gridLeft}></Grid>
        <Grid item xs={3} className={gridLeft}>
          <img
            className={img}
            src={Sara_createnewchatbot}
            alt={'Walk-through newchatbot'}
          />
        </Grid>
      </Grid>
      <Container maxWidth='lg'>
        <div className={root}>
          <Grid container spacing={4} className={container}>
            <Grid item xs={12} className={horizontalTitleGrid}>
              <Typography variant='h4'>My Chatbots and Statistics</Typography>
              <ChatbotTable />
            </Grid>
          </Grid>
        </div>
      </Container>
    </dev>
  );
};

export default withStyles(styles)(Dashboard);
