// import packages
import React, { useEffect, useRef, useContext } from 'react';

// import local dependencies
import ChatbotContext from '../../Context/Chatbot/chatbotContext';
import {
  createEditor,
  frageComponent,
  feedbackComponent,
  tippComponent,
  endpunktComponent,
} from './editor';

// import material-ui
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

// styling
const styles = (theme) => {
  return {
    root: {
      backgroundColor: '',
      margin: '0px',
      padding: '0px',
      height: '100%',
    },
    gridContainer: {
      backgroundColor: '#ededed',
      margin: '0px',
      padding: '1px',
      height: '100%',
    },
    reteButtons: {
      backgroundColor: 'white',
      margin: '0px',
      padding: '15px',
    },
    button: {
      fontFamily: 'Montserrat',
      color: 'white',
      marginBottom: '20px',
      backgroundColor: theme.palette.info.dark,
    },
    button1: {
      fontFamily: 'Montserrat',
      color: 'black',
      marginBottom: '20px',
      backgroundColor: theme.palette.secondary.main,
    },
    reteEditor: {
      backgroundColor: '',
      margin: '0px',
      padding: '3px',
    },
  };
};

const Rete = (props) => {
  // destructuring: styling props
  const {
    root,
    gridContainer,
    reteButtons,
    reteEditor,
    button,
    button1,
  } = props.classes;

  // global state: get access to chatbot context
  const chatbotContext = useContext(ChatbotContext);
  const { saveNodeToChatbot, currentChatbotInEdit } = chatbotContext;
  console.log(currentChatbotInEdit);

  // create container and editor elements
  const container = useRef(null);
  const editor = useRef(null);

  // component lifecycle: useEffect hook when component mounts or updates
  useEffect(() => {
    (async () => {
      editor.current = await createEditor(container.current);
    })();
  });

  // buttons
  const frageButtonHandler = async () => {
    var frageNode = await frageComponent.createNode();
    frageNode.position = [0, 200];
    editor.current.addNode(frageNode);
  };
  const feedbackButtonHandler = async () => {
    var feedbackNode = await feedbackComponent.createNode();
    feedbackNode.position = [0, 200];
    editor.current.addNode(feedbackNode);
  };
  const tippButtonHandler = async () => {
    var tippNode = await tippComponent.createNode();
    tippNode.position = [0, 200];
    editor.current.addNode(tippNode);
  };
  const endpunktButtonHandler = async () => {
    var endpunktNode = await endpunktComponent.createNode();
    endpunktNode.position = [0, 200];
    editor.current.addNode(endpunktNode);
  };
  const deleteButtonHandler = () => {
    editor.current.selected.list.forEach((element) => {
      editor.current.removeNode(element);
    });
  };

  // save chatbot
  const saveButtonHandler = () => {
    const editorJSON = editor.current.toJSON().nodes;
    Object.keys(editorJSON).map((key) => {
      let node = {};
      node.id = editorJSON[key].id;
      node.type = editorJSON[key].name;
      node.message = editorJSON[key].data.chatbottext;
      if (editorJSON[key].data.correctAnswers) {
        node.correctAnswers = editorJSON[key].data.correctAnswers.split(',');
      } else {
        node.correctAnswers = null;
      }
      node.position = {
        x: editorJSON[key].position[0],
        y: editorJSON[key].position[1],
      };

      if (editorJSON[key].outputs.output2) {
        node.outputs = {
          correct: editorJSON[key].outputs.output1.connections[0].node,
          incorrect: editorJSON[key].outputs.output2.connections[0].node,
        };
      } else if (editorJSON[key].outputs.output1) {
        node.outputs = {
          correct: editorJSON[key].outputs.output1.connections[0].node,
          incorrect: null,
        };
      } else {
        node.outputs = {
          correct: null,
          incorrect: null,
        };
      }
      const { id, type, message, correctAnswers, position, outputs } = node;
      if (
        typeof (
          id &&
          type &&
          message &&
          correctAnswers &&
          position.x &&
          position.y &&
          outputs.correct &&
          outputs.incorrect !== 'undefined'
        )
      ) {
        saveNodeToChatbot(node, currentChatbotInEdit);
        return 'Success';
      } else {
        alert('Incomplete chatbot, try again');
        return 'Error';
      }
    });
  };

  // react: return jsx
  return (
    <div className={root}>
      <Grid container className={gridContainer}>
        <Grid item xs={2} className={reteButtons}>
          <Button
            variant='contained'
            fullWidth
            className={button}
            onClick={frageButtonHandler}
          >
            Frage <br /> hinzufügen
          </Button>
          <Button
            variant='contained'
            fullWidth
            className={button}
            onClick={feedbackButtonHandler}
          >
            Feedback <br /> hinzufügen
          </Button>
          <Button
            variant='contained'
            fullWidth
            className={button}
            onClick={tippButtonHandler}
          >
            Tipp <br /> hinzufügen
          </Button>
          <Button
            variant='contained'
            fullWidth
            className={button}
            onClick={endpunktButtonHandler}
          >
            Endpunkt <br /> hinzufügen
          </Button>
          <Button
            variant='contained'
            fullWidth
            className={button}
            onClick={deleteButtonHandler}
          >
            Element <br /> löschen
          </Button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant='contained'
            fullWidth
            className={button1}
            onClick={saveButtonHandler}
          >
            Chatbot <br /> speichern
          </Button>
        </Grid>
        <Grid item xs={10} className={reteEditor}>
          <div
            style={{
              height: '81vh',
              maxWidth: '100%',
              borderStyle: '',
              margin: 'auto',
              marginTop: '5px',
            }}
            ref={container}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Rete);
