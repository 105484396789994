// import packages
import React, { useContext, useState, useEffect } from 'react';

// import local dependencies
import ChatContext from '../../Context/Chat/chatContext';
import Logo from './images/tubot_logo.png';
import Share from './Share';

// import material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import Textfield from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ReplayIcon from '@material-ui/icons/Replay';

// styling
const styles = (theme) => {
  return {
    root: {
      backgroundColor: '',
      padding: '5px',
      margin: '0px',
      height: '100%',
    },
    chatConversation: {
      backgroundColor: '',
      padding: '3px',
      margin: '0px',
      height: '74%',
      position: 'relative',
      maxHeight: '74%',
      overflowY: 'auto',
    },
    inputForm: {
      backgroundColor: '',
      padding: '5px',
      margin: '5px 0px 0px 0px',
    },
    inputTextfield: {
      backgroundColor: '',
      maxWidth: '75%',
    },
    inputIconButton: {
      backgroundColor: theme.palette.info.dark,
      float: 'right',
      type: 'submit',
      '&:hover': {
        backgroundColor: theme.palette.info.light,
      },
    },
    inputSendIcon: {
      color: 'white',
    },
    unorderedList: {
      listStyleType: 'none',
      width: '93%',
      margin: '5px',
      padding: '0',
      position: 'absolute',
      bottom: '10px',
    },
    itemUser: {
      marginTop: '10px',
      maxWidth: '17rem',
      backgroundColor: theme.palette.secondary.main,
      padding: '10px 20px 10px 20px',
      borderRadius: '30px',
    },
    itemChatbot: {
      marginTop: '10px',
      maxWidth: '17rem',
      backgroundColor: 'lightgrey',
      padding: '10px 20px 10px 20px',
      borderRadius: '30px',
    },
    header: {
      minHeight: '50px',
      width: '100%',
    },
    img: {
      height: 40,
      display: 'block',
      maxWidth: 40,
      overflow: 'hidden',
      width: '100%',
    },
    reloadChatButton: {
      marginLeft: '45%',
      marginTop: '3px',
      marginBottom: '1px',
    },
  };
};

const Chatbot = (props) => {
  // destructuring: styling props
  const {
    root,
    chatConversation,
    inputForm,
    inputTextfield,
    inputIconButton,
    inputSendIcon,
    unorderedList,
    itemUser,
    itemChatbot,
    img,
    header,
    reloadChatButton,
  } = props.classes;

  // global state: get access to chat context
  const chatContext = useContext(ChatContext);
  const {
    chat,
    createChatSessionId,
    addUserInputToChat,
    resetChatState,
  } = chatContext;

  // local state: create state for chatbot input field
  const [inputField, setInputField] = useState('');

  // component lifecycle: useEffect hook when component mounts or updates
  useEffect(
    () => {
      createChatSessionId();
    },
    // eslint-disable-next-line
    []
  );

  // function 1: save input to local state with every change
  const onChangeHandlerInput = (e) => {
    setInputField(e.target.value);
  };

  // function 2: add input to chat array
  const answerFormOnSubmit = (e) => {
    e.preventDefault();
    addUserInputToChat(inputField);
    setInputField('');
  };

  // function 3: chat reloader
  const chatReloader = () => {
    createChatSessionId();
    resetChatState('chatbot', 'Chat wurde resetted');
  };

  // react: return jsx
  return (
    <div className={root}>
      <Grid container item xs={12} className={header}>
        <Grid item xs={4}>
          <img className={img} src={Logo} alt={Logo} />
        </Grid>
        <Grid item xs={8}>
          <Share />
        </Grid>
      </Grid>
      <Divider />
      <div className={chatConversation}>
        <ul className={unorderedList}>
          {chat.map((chatItem, index) => {
            return (
              <li
                key={index}
                className={chatItem.account === 'user' ? itemUser : itemChatbot}
              >
                {chatItem.text}
              </li>
            );
          })}
        </ul>
      </div>
      <IconButton
        size='small'
        className={reloadChatButton}
        onClick={chatReloader}
      >
        <ReplayIcon />
      </IconButton>
      <hr />
      <form
        className={inputForm}
        noValidate
        autoComplete='off'
        spellCheck='false'
        onSubmit={answerFormOnSubmit}
      >
        <Textfield
          className={inputTextfield}
          id='chatInput'
          name='chatInput'
          type='text'
          label='Type in your answer...'
          fullWidth
          value={inputField}
          InputLabelProps={{ style: { fontSize: 13 } }}
          onChange={onChangeHandlerInput}
        />
        <IconButton className={inputIconButton} onClick={answerFormOnSubmit}>
          <SendIcon className={inputSendIcon} />
        </IconButton>
      </form>
    </div>
  );
};

export default withStyles(styles)(Chatbot);
