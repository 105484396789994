// import types
import {
  SESSION_ID_CREATED,
  ADD_BACKEND_ANSWER_TO_CHAT,
  ADD_USER_INPUT_TO_CHAT,
  RESET_CHAT_STATE,
} from './chatTypes';

export default (state, action) => {
  switch (action.type) {
    // action 1: create session id
    case SESSION_ID_CREATED:
      return {
        ...state,
        sessionId: action.payload,
      };

    // action 2: add backend answer to chat
    case ADD_BACKEND_ANSWER_TO_CHAT:
      return {
        ...state,
        chat: [...state.chat, action.payload],
      };

    // action 3: add user input to chat
    case ADD_USER_INPUT_TO_CHAT:
      return {
        ...state,
        chat: [...state.chat, action.payload],
      };

    // action 4: reset chat state
    case RESET_CHAT_STATE:
      return {
        ...state,
        chat: [action.payload],
      };

    // action 5: default case
    default:
      return state;
  }
};
