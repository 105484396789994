// import types
import {
  SUCCESSFUL_CHATBOT_FROM_USER_FETCH,
  NEW_QUIZBOT_CREATED,
} from './chatbotTypes';

export default (state, action) => {
  switch (action.type) {
    // action 1: successfully fetched chatbots from user
    case SUCCESSFUL_CHATBOT_FROM_USER_FETCH:
      return {
        ...state,
        userChatbots: action.payload,
      };
    // action 2: create a new quizbot
    case NEW_QUIZBOT_CREATED:
      return {
        ...state,
        currentChatbotInEdit: action.payload.newChatBotId,
        currentChatbotPin: action.payload.newChatBotPin,
      };
    // action 3: default case
    default:
      return state;
  }
};
