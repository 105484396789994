// import packages
import React from 'react';

// import local dependencies
import Logo from './images/Tubot_logo_2.png';

// material-ui
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// styling
const styles = (theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '500vh',
    },
    main: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[800],
    },
    logo: {
      flexGrow: 1,
      fontFamily: 'shadowsintolight',
      textDecoration: 'none',
      color: 'black',
    },
    footertitle: {
      fontFamily: 'Montserrat',
      marginRight: '20px',
      color: 'black',
    },
    img: {
      height: 80,
      display: 'block',
      maxWidth: 80,
      overflow: 'hidden',
      width: '100%',
    },
  };
};

const Footer = (props) => {
  // destructuring: styling props
  const { logo, footertitle, img } = props.classes;

  //react: return jsx
  return (
    <Typography
      component='div'
      style={{ backgroundColor: '#fff', height: '60vh' }}
    >
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box textAlign='center'>
            <br />
            <br />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box textAlign='center'>
            <Typography variant='h4' className={logo}>
              CreatemyBot
            </Typography>
            <br />
            <br />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box textAlign='center'>
            <Typography variant='h6' className={footertitle}>
              About us
            </Typography>
            <br />
            <Typography variant='h6' className={footertitle}>
              Contact
            </Typography>
            <br />
            <Typography variant='h6' className={footertitle}>
              Home
            </Typography>
            <br />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box textAlign='center'>
            <Typography variant='h6' className={footertitle}>
              How it works
            </Typography>
            <br />
            <Typography variant='h6' className={footertitle}>
              FAQ
            </Typography>
            <br />
            <Typography variant='h6' className={footertitle}>
              Login
            </Typography>
            <br />
          </Box>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <br />
          <br />
          <br />
          <Box textAlign='center'>
            <img className={img} src={Logo} alt={Logo} />
          </Box>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Box textAlign='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://material-ui.com/'>
          Teechr.ai
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Box>
    </Typography>
  );
};

export default withStyles(styles)(Footer);
