// import packages
import React from 'react';
import { Link } from 'react-router-dom';

// import local dependencies
import moreInformationQuizBot from '../Layout/images/MoreInformationQuizBot.png';
import SaraMoreInformation from '../Layout/images/Sara_goingToCanvas.png';

// import material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';

// styling
const styles = (theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    container: {
      backgroundColor: '',
      padding: '10px',
      margin: '-300px 0px 0px 0px',
    },
    quarterBodyGrid: {
      marginTop: '10px',
    },
    card: {
      padding: '15px',
      textAlign: 'center',
      height: '100%',
      marginBottom: '15px',
    },
    cardTitle: {
      fontFamily: 'Montserrat',
      marginBottom: '20px',
    },
    titleButton: {
      fontFamily: 'Montserrat',
      marginBottom: '5px',
      color: 'white',
      backgroundColor: '#4a148c',
      width: '100%',
    },
    moreinformationlink: {
      fontFamily: 'Montserrat',
      color: theme.palette.primary.dark,
    },
    gridLeft: {
      backgroundColor: '',
      height: 500,
    },
    img: {
      maxHeight: 400,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%',
    },
    cardBody: {},
  };
};

const MoreInfoQuizbot = (props) => {
  // destructuring: styling props
  const {
    root,
    container,
    quarterBodyGrid,
    card,
    cardTitle,
    cardBody,
    titleButton,
    moreinformationlink,
    gridLeft,
    img,
  } = props.classes;

  // react: return jsx
  return (
    <div>
      <br />
      <Grid container>
        <Grid item xs={1} className={gridLeft}></Grid>
        <Grid item xs={3} className={gridLeft}>
          <img
            className={img}
            src={SaraMoreInformation}
            alt={'goingToCanvas'}
          />
        </Grid>
      </Grid>
      <Container maxWidth='lg'>
        <div className={root}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Grid container spacing={4} className={container}>
            <Grid item xs={3} className={quarterBodyGrid}>
              <Card className={card} raised>
                <CardContent>
                  <Typography variant='h5' className={cardTitle}>
                    Quizbot
                  </Typography>
                  <Typography className={cardBody}>
                    Create a simple quizbot that asks students questions and
                    provides feedback on their answers!
                  </Typography>
                  <br />
                  <Typography className={moreinformationlink}>
                    <Link to='/createnew'>back to all bot types</Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant='contained'
                    color='white'
                    className={titleButton}
                    component={Link}
                    to='/Choose'
                  >
                    Get started
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={9} className={quarterBodyGrid}>
              <Card className={card} raised>
                <CardContent>
                  <img
                    src={moreInformationQuizBot}
                    alt={'moreInformationQuizBot'}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(MoreInfoQuizbot);
