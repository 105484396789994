// import packages
import React, { useState, useContext } from 'react';

// import local dependencies
import ChatbotContext from '../../Context/Chatbot/chatbotContext';
import Sara_fromscratchorlibrary from '../Layout/images/Sara_fromscratchorlibrary.png';

// import material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

// styling
const styles = (theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    container: {
      backgroundColor: '',
      padding: '10px',
      margin: '0px 0px 0px 0px',
    },
    horizontalTitleGrid: {
      backgroundColor: '',
      textAlign: 'center',
    },
    semiBodyGrid: {
      marginTop: '10px',
    },
    card: {
      padding: '15px 30px 15px 30px',
      textAlign: 'center',
      marginBottom: '15px',
    },
    cardTitle: {
      marginBottom: '20px',
    },
    cardBody: {},

    textField: {
      maxWidth: '100%',
    },
    gridLeft: {
      backgroundColor: '',
      height: 150,
    },
    img: {
      maxHeight: 450,
      display: 'block',
      maxWidth: 450,
      overflow: 'hidden',
      width: '100%',
    },
  };
};

const Choose = (props) => {
  // destructuring: styling & history props
  const {
    root,
    container,
    horizontalTitleGrid,
    semiBodyGrid,
    card,
    cardTitle,
    cardBody,
    textField,
    gridLeft,
    img,
  } = props.classes;
  const { history } = props;

  // global state: get access to chatbot context
  const chatbotContext = useContext(ChatbotContext);
  const { createNewQuizbot } = chatbotContext;

  // local state: create state for input field
  const [newQuizbotState, setnewQuizbotState] = useState({
    quizBotName: '',
    helperTextState: '',
    errorState: false,
  });
  const { quizBotName, helperTextState, errorState } = newQuizbotState;

  // function 1: add whatever is typed in to local state
  const quizBotNameHandler = (e) => {
    setnewQuizbotState({
      ...newQuizbotState,
      [e.target.name]: e.target.value,
    });
  };

  // function 2: create a new quizbot when form is submitted
  const createQuizbotFormSubmitHandler = async (e) => {
    e.preventDefault();
    if (quizBotName.length < 5) {
      setnewQuizbotState({
        ...newQuizbotState,
        helperTextState: 'Der Name muss mind. 5 Zeichen haben',
        errorState: true,
      });
    } else {
      const botType = 'quizbot';
      const isPublic = true;
      await createNewQuizbot(quizBotName, botType, isPublic);
      history.push('/canvas');
    }
  };

  // react: return jsx
  return (
    <div>
      <br />
      <Grid container>
        <Grid item xs={1} className={gridLeft}></Grid>
        <Grid item xs={3} className={gridLeft}>
          <img
            className={img}
            src={Sara_fromscratchorlibrary}
            alt={'fromscratchorlibrary'}
          />
        </Grid>
      </Grid>
      <Container maxWidth='lg'>
        <div className={root}>
          <Grid container spacing={4} className={container}>
            <Grid item xs={12} className={horizontalTitleGrid}>
              <Typography variant='h5'>
                What kind of Quizbot do you want to create?
              </Typography>
            </Grid>
            <Grid item xs={6} className={semiBodyGrid}>
              <Card className={card} raised>
                <CardContent>
                  <Typography variant='h5' className={cardTitle}>
                    From Scratch
                  </Typography>
                  <Typography className={cardBody}>
                    Create a simple quizbot from scratch!
                  </Typography>
                  <br />
                  <form
                    noValidate
                    autoComplete='off'
                    spellCheck='false'
                    onSubmit={createQuizbotFormSubmitHandler}
                  >
                    <TextField
                      className={textField}
                      id='createNewQuizbot'
                      name='quizBotName'
                      type='text'
                      label='Gib deinem Quizbot einen Namen'
                      placeholder='Gib deinem Quizbot einen Namen...'
                      helperText={helperTextState}
                      error={errorState}
                      value={quizBotName}
                      onChange={quizBotNameHandler}
                      fullWidth
                    />
                    <br />
                    <br />
                    <Button
                      type='submit'
                      size='medium'
                      fullWidth
                      variant='contained'
                      color='secondary'
                    >
                      Get started
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} className={semiBodyGrid}>
              <Card className={card} raised>
                <CardContent>
                  <Typography variant='h5' className={cardTitle}>
                    Import From Library
                  </Typography>
                  <Typography className={cardBody}>
                    Search for existing quizbots in the library!
                  </Typography>
                  <br />
                  <form noValidate autoComplete='off' spellCheck='false'>
                    <TextField
                      className={textField}
                      id='searchQuizbot'
                      name='searchQuizbot'
                      type='text'
                      label='Suche einen Quizbot'
                      placeholder='Ich suche nach...'
                      disabled
                      fullWidth
                    />
                    <br />
                    <br />
                    <Button
                      type='submit'
                      size='medium'
                      fullWidth
                      variant='contained'
                      color='secondary'
                      disabled
                    >
                      Coming soon...
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Choose);
