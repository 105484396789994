// import packages
import React, { useReducer, useContext } from 'react';
import axios from 'axios';

// import local dependencies
import AuthContext from '../Auth/authContext';
import ChatbotContext from './chatbotContext';
import chatbotReducer from './chatbotReducer';
import {
  SUCCESSFUL_CHATBOT_FROM_USER_FETCH,
  NEW_QUIZBOT_CREATED,
} from './chatbotTypes';

const ChatbotState = (props) => {
  // global state: get access to authentication context
  const authContext = useContext(AuthContext);
  const { token } = authContext;

  // initial chatbotState: create initial chatbot state
  const initialChatbotState = {
    userChatbots: [],
  };

  // chatbot state: get access to state object and dispatch function
  const [state, dispatch] = useReducer(chatbotReducer, initialChatbotState);

  // action 1: fetch chatbots from user
  const fetchChatbotFromUser = async () => {
    const config = {
      headers: {
        'x-auth-token': token,
      },
    };
    try {
      const res = await axios.get(
        'https://sandbox.as.wiwi.uni-goettingen.de/teachr/chatbots',
        config
      );
      const userChatbots = res.data;
      dispatch({
        type: SUCCESSFUL_CHATBOT_FROM_USER_FETCH,
        payload: userChatbots,
      });
    } catch (error) {
      console.error(error);
      console.log('Error fetching chatbots from the user');
    }
  };

  // action 2: create a new quizbot
  const createNewQuizbot = async (quizBotName, botType, isPublic) => {
    const data = {
      name: quizBotName,
      type: botType,
      public: isPublic,
    };
    const config = {
      headers: {
        'x-auth-token': token,
      },
    };
    try {
      const res = await axios.post(
        'https://sandbox.as.wiwi.uni-goettingen.de/teachr/chatbots',
        data,
        config
      );
      const payloadObject = {
        newChatBotId: res.data.id,
        newChatBotPin: res.data.pin,
      };
      dispatch({
        type: NEW_QUIZBOT_CREATED,
        payload: payloadObject,
      });
    } catch (error) {
      console.error(error);
      console.log('Error creating a new chatbot');
    }
  };

  // action 3: save nodes to existing chatbot
  const saveNodeToChatbot = async (node, chatbotID) => {
    const config = {
      headers: {
        'x-auth-token': token,
      },
    };
    try {
      const res = await axios.put(
        `https://sandbox.as.wiwi.uni-goettingen.de/teachr/chatbots/${chatbotID}/node/${node.id}`,
        node,
        config
      );
      const nodesSavedToChatbot = res.data;
      console.log(
        `Node ${nodesSavedToChatbot.id} has been saved to the chatbot`
      );
    } catch (error) {
      console.log(error);
      console.log('error saving nodes to chatbot');
    }
  };

  // react: return jsx
  return (
    <ChatbotContext.Provider
      value={{
        userChatbots: state.userChatbots,
        currentChatbotInEdit: state.currentChatbotInEdit,
        currentChatbotPin: state.currentChatbotPin,
        fetchChatbotFromUser,
        createNewQuizbot,
        saveNodeToChatbot,
      }}
    >
      {props.children}
    </ChatbotContext.Provider>
  );
};

export default ChatbotState;
