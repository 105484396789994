import React from 'react';
import { Control } from 'rete';

// Create new React class component
class MyReactControl extends React.Component {
  // InitialState
  state = {
    value: ''
  };
  // LCM method when Component mounts to DOM
  componentDidMount() {
    this.setState({
      placeholder: this.props.placeholder
    });
    this.props.putData(this.props.id, this.props.placeholder);
  }
  // Input OnChange Handler
  onChange(event) {
    this.props.putData(this.props.id, event.target.value);
    this.props.emitter.trigger('process');
    this.setState({
      value: event.target.value
    });
  }
  // Return input field from this function
  render() {
    return (
      <textarea
        placeholder={this.state.placeholder}
        value={this.state.value}
        onChange={this.onChange.bind(this)}
        rows='6'
        style={{ fontSize: '18px' }}
        onFocus={e => (e.target.placeholder = '')}
        onBlur={e => (e.target.placeholder = this.state.placeholder)}
      />
    );
  }
}

// Export the entire MyControl (MyReactControl is only part of it) to Editor
export class MyControl extends Control {
  constructor(emitter, node, key, placeholder) {
    super(key);
    this.render = 'react';
    this.component = MyReactControl;
    this.props = {
      emitter,
      id: key,
      placeholder,
      putData: (id, value) => {
        node.data[id] = value;
      }
    };
  }
}
