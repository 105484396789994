// imports packages
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import local dependencies
import AuthState from './Context/Auth/AuthState';
import ChatbotState from './Context/Chatbot/ChatbotState';
import ChatState from './Context/Chat/ChatState';
import Home from './Components/Pages/Home';
import Register from './Components/Pages/Register';
import Login from './Components/Pages/Login';
import Dashboard from './Components/Pages/Dashboard';
import Choose from './Components/Pages/Choose';
import Canvas from './Components/Pages/Canvas';
import Createnew from './Components/Pages/Createnew';
import Navbar from './Components/Layout/Navbar';
import PrivateRoute from './Utils/PrivateRoute';
import MoreInfoQuizbot from './Components/Pages/MoreInfoQuizbot';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { teechrTheme } from './Utils/teechrTheme';
import CssBaseline from '@material-ui/core/CssBaseline';


const App = () => {
  return (
    <AuthState>
      <ChatbotState>
        <ChatState>
          <MuiThemeProvider theme={teechrTheme}>
            <CssBaseline />
            <Router>
              <Navbar />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/createnew" component={Createnew} />
                <PrivateRoute exact path="/choose" component={Choose} />
                <PrivateRoute exact path="/canvas" component={Canvas} />
                <PrivateRoute
                  exact
                  path='/moreinformationquizbot'
                  component={MoreInfoQuizbot}
                />
              </Switch>
            </Router>
          </MuiThemeProvider>
        </ChatState>
      </ChatbotState>
    </AuthState>
  );
};

export default App;
