// import packages
import React from 'react';

// import local dependencies
import Rete from '../Rete/Rete';
import Chatbot from '../Layout/Chatbot';
import SaraCanvas1 from '../Layout/images/Sara_canvas_1.png';
import SaraCanvas2 from '../Layout/images/Sara_canvas_2.png';
import SaraCanvas3 from '../Layout/images/Sara_canvas_3.png';
import SaraCanvas4 from '../Layout/images/Sara_canvas_4.png';
import SaraCanvas5 from '../Layout/images/Sara_canvas_5.png';

// import material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

// styling
const styles = (theme) => {
  return {
    root: {
      backgroundColor: '',
      margin: '0px',
      padding: '1px',
      height: 'calc(100vh - 75px)',
    },
    gridContainer: {
      backgroundColor: '',
      margin: '0px',
      padding: '1px',
      maxHeight: '80%',
    },
    gridLeft: {
      backgroundColor: '',
      height: 150,
    },
    img: {
      display: 'block',
      maxWidth: '100%',
      overflow: 'hidden',
    },
  };
};

const Canvas = (props) => {
  // destructuring: styling props
  const { root, gridContainer, gridLeft, img } = props.classes;

  // react: return jsx
  return (
    <div>
      <br />
      <Grid container>
        <Grid item xs={3} className={gridLeft}>
          <img className={img} src={SaraCanvas1} alt={'Canvas1'} />
        </Grid>
        <Grid item xs={2} className={gridLeft}>
          <img className={img} src={SaraCanvas2} alt={'Canvas2'} />
        </Grid>
        <Grid item xs={2} className={gridLeft}>
          <img className={img} src={SaraCanvas3} alt={'Canvas3'} />
        </Grid>
        <Grid item xs={2} className={gridLeft}>
          <img className={img} src={SaraCanvas4} alt={'Canvas4'} />
        </Grid>
        <Grid item xs={2} className={gridLeft}>
          <img className={img} src={SaraCanvas5} alt={'Canvas5'} />
        </Grid>
      </Grid>
      <div className={root}>
        <Grid container className={gridContainer}>
          <Grid item xs={10}>
            <Rete />
          </Grid>
          <Grid item xs={2}>
            <Chatbot />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(Canvas);
