// import packages
import React, { useReducer } from 'react';
import axios from 'axios';

// import local dependencies
import AuthContext from './authContext';
import authReducer from './authReducer';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_USER_DATA,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_USER,
} from './authTypes';

const AuthState = (props) => {
  // initial authState: create initial authentication state
  const initialAuthState = {
    token: sessionStorage.getItem('token'),
    isAuthenticated: false,
    user: null,
    loading: false,
    errorMessage: null,
  };

  // auth state: get access to state object and dispatch function
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  // action 1: load user information
  const loadUserInfo = async (token) => {
    const config = {
      headers: {
        'x-auth-token': token,
      },
    };
    try {
      const res = await axios.get(
        'https://sandbox.as.wiwi.uni-goettingen.de/teachr/user/data',
        config
      );
      const userData = res.data;
      dispatch({
        type: SET_USER_DATA,
        payload: userData,
      });
    } catch (error) {
      console.error(error);
      console.log('Error while loading user data');
    }
  };

  // action 2: register user
  const registerUser = async (dataFromRegistrationForm) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(
        'https://sandbox.as.wiwi.uni-goettingen.de/teachr/user',
        dataFromRegistrationForm,
        config
      );
      const bearer = res.data.token;
      dispatch({
        type: REGISTER_SUCCESS,
        payload: bearer,
      });
    } catch (err) {
      const errorMsg = err.response.data.message;
      dispatch({
        type: REGISTER_FAIL,
        payload: errorMsg,
      });
      console.log(err.response.data.message);
      console.log('Error during signup');
    }
  };

  // action 3: login user
  const loginUser = async (dataFromLoginForm) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(
        'https://sandbox.as.wiwi.uni-goettingen.de/teachr/user/login',
        dataFromLoginForm,
        config
      );
      const bearer = res.data.token;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: bearer,
      });
    } catch (err) {
      const errorMsg = err.response.data.message;
      dispatch({
        type: LOGIN_FAIL,
        payload: errorMsg,
      });
      console.log(err.response.data.message);
      console.log('Error during login');
    }
  };

  // action 4: logout user
  const logoutUser = () => {
    dispatch({
      type: LOGOUT_USER,
    });
  };

  // react: return jsx
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        loading: state.loading,
        errorMessage: state.errorMessage,
        loadUserInfo,
        registerUser,
        loginUser,
        logoutUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
