// import packages
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

// import local dependencies
import AuthContext from '../Context/Auth/authContext';

const PrivateRoute = (props) => {
  // destructuring: component props
  const { component: Component, ...rest } = props;

  // global state: get access to authentication context
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;

  // react: return jsx
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? <Redirect to='/login' /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;
