// import packages
import React from 'react';

// import material-ui
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const ChatbotTableItem = (props) => {
  // destructuring: chat props
  const { name, type, link } = props.chatbotProps;

  // react: return jsx
  return (
    <TableRow>
      <TableCell component='th' scope='row'>
        {name}
      </TableCell>
      <TableCell align='right'>{type}</TableCell>
      <TableCell align='right'>
        {name}
        <IconButton
          onClick={() => {
            alert(`${link} has been copied to clipboard`);
          }}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </TableCell>
      <TableCell align='right'>
        <IconButton>
          <MoreHorizOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ChatbotTableItem;
