// import packages
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import local dependencies
import Sara_login from '../Layout/images/Sara_login.png';
import AuthContext from '../../Context/Auth/authContext';

// import material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// styling
const styles = (theme) => ({
  container: {
    marginTop: '40px',
    textAlign: 'center',
  },
  cardWrapper: {
    padding: '30px',
  },
  textField: {
    marginBottom: '15px',
  },
  button: {
    marginTop: '20px',
    marginBottom: '10px',
    position: 'relative',
  },
  circularProgress: {
    position: 'absolute',
  },
  gridLeft: {
    backgroundColor: '',
    height: 200,
  },
  img: {
    maxHeight: 400,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
});

const Login = (props) => {
  // destructuring: styling & history props
  const {
    container,
    cardWrapper,
    textField,
    button,
    gridLeft,
    img,
  } = props.classes;
  const { history } = props;

  // global state: get access to authentication context
  const authContext = useContext(AuthContext);
  const { loginUser, isAuthenticated, errorMessage } = authContext;

  // local state: create state for login form
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const { email, password } = form;

  // local state: create state for client-side errors
  const [clientError, setClientError] = useState({
    emailError: false,
    emailHelperText: '',
    passwordError: false,
    passwordHelperText: '',
  });
  const {
    emailError,
    emailHelperText,
    passwordError,
    passwordHelperText,
  } = clientError;

  // local state: create state for server-side errors
  const [serverError, setServerError] = useState('');

  // component lifecycle: useEffect hook when component mounts or updates
  useEffect(() => {
    if (errorMessage !== null) {
      setServerError(errorMessage);
    }
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, history, errorMessage]);

  // fucntion 1: onChange handler on the input fields
  const onChangeHandlerInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // function 2: form submitter
  const onSubmitHandlerForm = (e) => {
    e.preventDefault();
    if (email.length < 1) {
      setClientError({
        ...clientError,
        emailError: true,
        emailHelperText: 'Enter an email address',
      });
      window.setTimeout(() => {
        setClientError({
          ...clientError,
          emailError: false,
          emailHelperText: '',
        });
      }, 4000);
    } else if (password.length < 1) {
      setClientError({
        ...clientError,
        passwordError: true,
        passwordHelperText: 'Enter a password',
      });
      window.setTimeout(() => {
        setClientError({
          ...clientError,
          passwordError: false,
          passwordHelperText: '',
        });
      }, 4000);
    } else {
      loginUser({
        email: email,
        password: password,
      });
    }
  };

  // react: return jsx
  return (
    <dev>
      <br />
      <Grid container>
        <Grid item xs={1} className={gridLeft}></Grid>
        <Grid item xs={3} className={gridLeft}>
          <img className={img} src={Sara_login} alt={'Walk-through register'} />
        </Grid>
      </Grid>
      <Container maxWidth='sm' className={container}>
        <Card className={cardWrapper} raised>
          <Typography variant='h5' align='left' gutterBottom>
            Login
          </Typography>
          <form
            noValidate
            autoComplete='off'
            spellCheck='false'
            onSubmit={onSubmitHandlerForm}
          >
            <TextField
              id='email'
              name='email'
              type='email'
              label='Email Address'
              placeholder='Enter your email address...'
              fullWidth
              error={emailError}
              helperText={emailHelperText}
              className={textField}
              value={email}
              onChange={onChangeHandlerInput}
            />
            <TextField
              id='password'
              name='password'
              type='password'
              autoComplete='current-password'
              label='Password'
              placeholder='Enter your password...'
              fullWidth
              error={passwordError}
              helperText={passwordHelperText}
              className={textField}
              value={password}
              onChange={onChangeHandlerInput}
            />
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              size='large'
              className={button}
              fullWidth
            >
              Login
            </Button>
            <Typography color='error'>
              <small>{serverError}</small>
            </Typography>
            <small>
              Don't have an account? Sign up <Link to='/register'>here</Link>
            </small>
          </form>
        </Card>
      </Container>
    </dev>
  );
};

export default withStyles(styles)(Login);
