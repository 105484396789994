// import types
import {
  SET_USER_DATA,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_USER,
} from './authTypes';

export default (state, action) => {
  switch (action.type) {
    // action 1: get user data
    case SET_USER_DATA:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
        errorMessage: null,
      };

    // action 2: registration success
    case REGISTER_SUCCESS:
      sessionStorage.setItem('token', action.payload);
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true,
        user: null,
        loading: false,
        errorMessage: null,
      };

    // action 2: registration failure
    case REGISTER_FAIL:
      sessionStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        errorMessage: action.payload,
      };

    // action 3: login success
    case LOGIN_SUCCESS:
      sessionStorage.setItem('token', action.payload);
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true,
        user: null,
        loading: false,
        errorMessage: null,
      };

    // action 3: login failure
    case LOGIN_FAIL:
      sessionStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        errorMessage: action.payload,
      };

    // action 4: logout user
    case LOGOUT_USER:
      sessionStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        errorMessage: null,
      };

    // case 5: student login success

    // case 5: default case
    default:
      return state;
  }
};
