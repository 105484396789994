// import packages
import React, { useContext } from 'react';

// import local dependencies
import Share from './images/Share.png';
import SaraFinish from './images/Sara_canvas_6.png';
import ChatbotContext from '../../Context/Chatbot/chatbotContext';

// import material-ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// styling
const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  shareButton: {
    fontFamily: 'Montserrat',
    marginBottom: '5px',
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
  },
  img: {
    maxHeight: 450,
    display: 'block',
    maxWidth: 600,
    overflow: 'hidden',
  },
  img1: {
    maxHeight: 150,
    display: 'block',
    maxWidth: 300,
    overflow: 'hidden',
  },
}));

export default function MaxWidthDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  }; */

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  // global state: get access to chatbot context
  const chatbotContext = useContext(ChatbotContext);
  const { currentChatbotPin } = chatbotContext;

  return (
    <React.Fragment>
      <Button className={classes.titleButton} onClick={handleClickOpen}>
        Share
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='max-width-dialog-title'>
          Share your Bot with your students. PIN: {currentChatbotPin}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img className={classes.img1} src={SaraFinish} alt={'Share'} />
          </DialogContentText>
          <DialogContentText>
            <img className={classes.img} src={Share} alt={'Share'} />
          </DialogContentText>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch checked={fullWidth} onChange={handleFullWidthChange} />
            }
            label='Share chatbot with library'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
