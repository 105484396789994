// import packages
import React, { useContext } from 'react';

// import local dependencies
import ChatbotContext from '../../Context/Chatbot/chatbotContext';
import ChatbotTableItem from './ChatbotTableItem';

// import material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// styling
const styles = (theme) => {
  return {
    paper: {
      marginTop: '30px',
      marginBottom: '50px',
    },
    table: {
      minWidth: 650,
    },
  };
};

const ChatbotTable = (props) => {
  // destructuring: styling props
  const { table, paper } = props.classes;

  // global state: get access to chatbot context
  const chatbotContext = useContext(ChatbotContext);
  const { userChatbots } = chatbotContext;

  // react: return jsx
  return (
    <Paper elevation={5} className={paper}>
      <TableContainer>
        <Table className={table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Chatbot name</TableCell>
              <TableCell align='right'>Type</TableCell>
              <TableCell align='right'>Link</TableCell>
              <TableCell align='right'>More</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userChatbots.map((singleChatbot) => {
              return (
                <ChatbotTableItem
                  key={singleChatbot.id}
                  chatbotProps={singleChatbot}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default withStyles(styles)(ChatbotTable);
