// Import Rete stuff
import Rete from 'rete';
import ReactRenderPlugin from 'rete-react-render-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import AreaPlugin from 'rete-area-plugin';

// Import Nodes & Controls
import { MyNode } from './Node';
import { MyControl } from './Control';

// Create a new Socket for the five different Nodes to use
var numSocket = new Rete.Socket('Number value');

// Disable recursion detection error message
const errorLog = console.error;
console.error = (data) => {
  if (data && data.message && data.message === 'Recursion detected') {
    return;
  }
  errorLog(data);
};

// Startpunkt Node
class Startpunkt extends Rete.Component {
  constructor() {
    super('Startpunkt');
  }

  // Build node elements
  builder(node) {
    // new output
    var out1 = new Rete.Output('output1', 'Output', numSocket, false);
    // new control
    var ctrl1 = new MyControl(
      this.editor,
      node,
      'chatbottext',
      'Begrüssungsnachricht für den Schüler (z.B. "Willkommen beim Sek 3a Mathebot. Bist du bereit für das Quiz?")'
    );

    // Add elements to node
    return node.addOutput(out1).addControl(ctrl1);
  }
}

// 2 - Frage Node
class Frage extends Rete.Component {
  constructor() {
    super('Frage');
  }

  // Build node elements
  builder(node) {
    // new input
    var inp = new Rete.Input('input1', 'Input', numSocket, true);
    // new output1
    var out1 = new Rete.Output('output1', 'Correct', numSocket, false);
    // new output2
    var out2 = new Rete.Output('output2', 'Incorrect', numSocket, false);
    // new control1
    var ctrl1 = new MyControl(
      this.editor,
      node,
      'chatbottext',
      'Stelle dem Schüler eine Frage (z.B. "Welches ist der höchste Berg der Welt?")'
    );
    // new control2
    var ctrl2 = new MyControl(
      this.editor,
      node,
      'correctAnswers',
      'Füge mögliche korrekte Antworten kommasepariert auf... (z. B. Mount Everest, Es ist Mount Everest, ich glaube Mount everest, etc...)'
    );

    // Add elements to node
    return node
      .addInput(inp)
      .addOutput(out1)
      .addOutput(out2)
      .addControl(ctrl1)
      .addControl(ctrl2);
  }
}

// 3 - Feedback Node
class Feedback extends Rete.Component {
  constructor() {
    super('Feedback');
  }

  // Build node elements
  builder(node) {
    // new input1
    var inp1 = new Rete.Input('input1', 'Input', numSocket, true);
    // new output1
    var out1 = new Rete.Output('output1', 'Output', numSocket, false);
    // new control1
    var ctrl1 = new MyControl(
      this.editor,
      node,
      'chatbottext',
      'Gib dem Schüler ein kurzes Feedback (z.B. "Super gemacht, weiter geht\'s...")'
    );

    // Add elements to node
    return node.addInput(inp1).addOutput(out1).addControl(ctrl1);
  }
}

// 4 - Tipp Node
class Tipp extends Rete.Component {
  constructor() {
    super('Tipp');
  }

  // Build node elements
  builder(node) {
    // add input1
    var inp1 = new Rete.Input('input1', 'Input', numSocket, true);
    // add output1
    var out1 = new Rete.Output('output1', 'Output', numSocket, false);
    // add control1
    var ctrl1 = new MyControl(
      this.editor,
      node,
      'chatbottext',
      'Gib dem Schüler einen kleinen Tipp (z.B "Startet mit A...")'
    );

    // Add elements to node
    return node.addInput(inp1).addOutput(out1).addControl(ctrl1);
  }
}

// 5 - Endpunkt Node
class Endpunkt extends Rete.Component {
  constructor() {
    super('Endpunkt');
  }

  // Build node elements
  builder(node) {
    // add input1
    var inp1 = new Rete.Input('input1', 'Input', numSocket, true);
    // add control1
    var ctrl1 = new MyControl(
      this.editor,
      node,
      'chatbottext',
      'Schlussnachricht für den Schüler (z.B. "Yaay, du bist am Ende angelangt!")'
    );

    return node.addInput(inp1).addControl(ctrl1);
  }
}

// Erstelle neue Instanz jeder Klasse und lege sie in einer eigenen Variable ab, damit man damit im UI neue Nodes erstellen kann
export const startpunktComponent = new Startpunkt();
export const frageComponent = new Frage();
export const feedbackComponent = new Feedback();
export const tippComponent = new Tipp();
export const endpunktComponent = new Endpunkt();

// Editor für AppJS
export async function createEditor(container) {
  // Ziehe alle oben definierten Components rein
  const components = [
    startpunktComponent,
    frageComponent,
    feedbackComponent,
    tippComponent,
    endpunktComponent,
  ];

  // Erstelle den Editor, der in ein HTML Element (container) gerendert sowie die Engine
  var editor = new Rete.NodeEditor('demo@0.1.0', container);
  var engine = new Rete.Engine('demo@0.1.0');

  // Nutze das Connection Plugin und das ReactRenderPlugin
  editor.use(ConnectionPlugin);
  editor.use(ReactRenderPlugin, {
    component: MyNode,
  });

  // Registriere den editor und die engine
  components.map(function (c) {
    editor.register(c);
    engine.register(c);
    return 'mapped';
  });

  // Kreiere mit jedem gestarteten Editor einen Startpunkt, die erste Frage und einen Endpunkt
  var startpunktNode = await components[0].createNode();
  var frageNode = await components[1].createNode();
  var endpunktNode = await components[4].createNode();
  // Definiere die Position der vorgefertigten Nodes
  startpunktNode.position = [0, 100];
  frageNode.position = [300, 100];
  endpunktNode.position = [900, 200];
  // Füge die vorgefertigten Nodes in den Editor ein
  editor.addNode(startpunktNode);
  editor.addNode(frageNode);
  editor.addNode(endpunktNode);
  // Verbinde die vorgefertigten Nodes miteinander
  editor.connect(
    startpunktNode.outputs.get('output1'),
    frageNode.inputs.get('input1')
  );

  // Erstelle die Editor view
  editor.view.resize();
  AreaPlugin.zoomAt(editor, editor.nodes);

  // Gib den Editor zurück, damit man auch in der überliegenden Komponente damit arbeiten kann (z.B. Zugriff auf den State)
  return editor;
}
