// import packages
import React, { useReducer, useContext } from 'react';
import axios from 'axios';

// import local dependencies
import AuthContext from '../../Context/Auth/authContext';
import ChatbotContext from '../../Context/Chatbot/chatbotContext';
import chatReducer from './chatReducer';
import ChatContext from './chatContext';
import {
  SESSION_ID_CREATED,
  ADD_BACKEND_ANSWER_TO_CHAT,
  ADD_USER_INPUT_TO_CHAT,
  RESET_CHAT_STATE,
} from './chatTypes';

const ChatState = (props) => {
  // global state: get access to authentication context
  const authContext = useContext(AuthContext);
  const { token } = authContext;

  // global state: get access to chatbot context
  const chatbotContext = useContext(ChatbotContext);
  const { currentChatbotInEdit } = chatbotContext;

  // initial chatState: create initial chat state
  const initialChatState = {
    sessionId: null,
    chat: [
      {
        account: 'chatbot',
        text: 'Type in anything to get started..',
        type: null,
      },
    ],
  };

  // chat state: get access to state object and dispatch function
  const [state, dispatch] = useReducer(chatReducer, initialChatState);

  // action 1: sessionID generator
  const createChatSessionId = () => {
    const sessionId = Math.random() * 10000000000000000;

    dispatch({
      type: SESSION_ID_CREATED,
      payload: sessionId,
    });
  };

  // action 2: fetch answer from backend and add answer to state
  const fetchAnswerFromBackend = async (userInput) => {
    const config = {
      headers: {
        'x-auth-token': token,
      },
    };
    const body = {
      session: state.sessionId,
      message: userInput,
    };
    try {
      const res = await axios.post(
        `https://sandbox.as.wiwi.uni-goettingen.de/teachr/chatbots/${currentChatbotInEdit}/chat`,
        body,
        config
      );
      const answerFromBackend = {
        account: 'chatbot',
        text: res.data.message,
        type: res.data.type,
      };
      dispatch({
        type: ADD_BACKEND_ANSWER_TO_CHAT,
        payload: answerFromBackend,
      });
    } catch (error) {
      console.error(error);
      console.log('Error in fetchAnswerFromBackend function');
    }
  };

  // action 3: add userInput to chat
  const addUserInputToChat = (userInput) => {
    const inputFromUser = {
      account: 'user',
      text: userInput,
      type: null,
    };
    dispatch({
      type: ADD_USER_INPUT_TO_CHAT,
      payload: inputFromUser,
    });
    setTimeout(() => {
      fetchAnswerFromBackend(userInput);
    }, 1000);
  };

  // action 4: reset chat state
  const resetChatState = (account, text) => {
    const inputFromUser = {
      account: account,
      text: text,
      type: null,
    };
    dispatch({
      type: RESET_CHAT_STATE,
      payload: inputFromUser,
    });
  };

  // react: return jsx
  return (
    <ChatContext.Provider
      value={{
        chat: state.chat,
        createChatSessionId,
        addUserInputToChat,
        resetChatState,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

export default ChatState;
