// import packages
import React from "react";

//material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

// styling
const styles = theme => {
  return {
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.default
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  };
};

function getSteps() {
  return [
    "Choose your chatbot type",
    "Design and Test the Interaction",
    "Share with your Students"
  ];
}

const Breadcrumbs = props => {
  const { root } = props.classes;

  const steps = getSteps();

  return (
    <div className={root}>
      <Stepper className={root} alternativeLabel activeStep={0}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withStyles(styles)(Breadcrumbs);
