// import packages
import React from "react";

// import local dependencies
import Breadcrumb_home from "./images/Breadcrump_step1.png";
import Breadcrumb_design from "./images/1 - Design.png";
import Breadcrumb_create from "./images/1 - Create New.png";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

// styling
const styles = theme => {
  return {
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.default
    },
    button: {
      fontFamily: "Montserrat",
      width: "50%",
      textAlign: "left",
      textTransform: "none",
      backgroundColor: theme.palette.background.default,
      color: "black"
    },
    actionsContainer: {
      marginBottom: theme.spacing(2)
    },
    resetContainer: {
      padding: theme.spacing(3)
    },
    iconContainer: {
      color: "black",
      backgroundColor: theme.palette.info.dark
    },
    gridLeft: {
      backgroundColor: ""
    },
    active: {
      color: "#784af4"
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor"
    },
    completed: {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18
    },
    img: {
      maxHeight: 500,
      display: "block",
      maxWidth: 800,
      overflow: "hidden",
      width: "100%"
    }
  };
};

const Breadcrumbs_home = props => {
  //styling
  const { root, button, active, gridLeft, img } = props.classes;
  //jsx
  const getSteps = steps => {
    return [
      "Choose your Chatbot type",
      "Search from library or create new one",
      "Design and share your bot"
    ];
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return `Choose your perfect chatbot type for our learning environment. You can choose between a quizbot, tutorbot, method bot or reflection bot`;
      case 1:
        return "Search for existing bots in our libary, where other teachers uploaded their bots or create one from scratch!";
      case 2:
        return `Create your questions, answers and hints and simultanously test them in our chat window. When you are satisfied, share the bot with your students!`;
      default:
        return "Unknown step";
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const openStep = step => () => {
    setActiveStep(step);
  };

  return (
    <div className={(root, { [active]: active })}>
      <Grid container spacing={2}>
        <Grid item xs={5} className={gridLeft}>
          <Stepper
            className={root}
            activeStep={activeStep}
            orientation="vertical"
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel onClick={openStep(index)}>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <br />
                  <div className={active}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={button}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={7} className={gridLeft}>
          {activeStep === 0 ? (
            <img className={img} src={Breadcrumb_home} alt={"step1"} />
          ) : activeStep === 1 ? (
            <img className={img} src={Breadcrumb_create} alt={"create"} />
          ) : (
            <img className={img} src={Breadcrumb_design} alt={"design"} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Breadcrumbs_home);

Breadcrumbs_home.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};
