// import packages
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import validator from 'email-validator'; // reactivate after eval

// import local dependencies
import Sara_register from '../Layout/images/Sara_register.png';
import AuthContext from '../../Context/Auth/authContext';

// import material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// styling
const styles = () => ({
  container: {
    marginTop: '40px',
    textAlign: 'center',
  },
  card: {
    padding: '30px',
  },
  textField: {
    marginBottom: '15px',
  },
  button: {
    marginTop: '20px',
    marginBottom: '10px',
    position: 'relative',
  },
  gridLeft: {
    backgroundColor: '',
    height: 200,
  },
  circularProgress: {
    position: 'absolute',
  },
  img: {
    maxHeight: 300,
    display: 'block',
    maxWidth: 300,
    overflow: 'hidden',
    width: '100%',
  },
});

const Register = (props) => {
  // destructuring: styling & history props
  const { container, card, textField, button, gridLeft, img } = props.classes;
  const { history } = props;

  // global state: get access to authentication context
  const authContext = useContext(AuthContext);
  const { registerUser, isAuthenticated, errorMessage } = authContext;

  // local state: create state for register form
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const { firstName, lastName, email, password, confirmPassword } = form;

  // local state: create state for client-side errors
  const [clientError, setClientError] = useState({
    firstNameError: false,
    firstNameHelperText: '',
    lastNameError: false,
    lastNameHelperText: '',
    emailError: false,
    emailHelperText: '',
    passwordError: false,
    passwordHelperText: '',
    confirmPasswordError: false,
    confirmPasswordHelperText: '',
  });
  const {
    firstNameError,
    firstNameHelperText,
    lastNameError,
    lastNameHelperText,
    emailError,
    emailHelperText,
    passwordError,
    passwordHelperText,
    confirmPasswordError,
    confirmPasswordHelperText,
  } = clientError;

  // local state: create state for server-side errors
  const [serverError, setServerError] = useState('');

  // component lifecycle: useEffect hook when component mounts or updates
  useEffect(() => {
    if (errorMessage !== null) {
      setServerError(errorMessage);
    }
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, history, errorMessage]);

  // function 1: add all inputs directly to local state
  const onChangeHandlerInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // function 2: check for errors and submit form to backend
  const onSubmitHandlerForm = (e) => {
    e.preventDefault();
    if (firstName.length < 1) {
      setClientError({
        ...clientError,
        firstNameError: true,
        firstNameHelperText: 'This field must not be empty',
      });
    } else if (lastName.length < 1) {
      setClientError({
        ...clientError,
        lastNameError: true,
        lastNameHelperText: 'This field must not be empty',
      });
    } /* else if (validator.validate(email) !== true) {
      setClientError({
        ...clientError,
        emailError: true,
        emailHelperText: 'Enter a valid email address',
      });
      window.setTimeout(() => {
        setClientError({
          ...clientError,
          emailError: false,
          emailHelperText: '',
        });
      }, 5000);
    } */ else if (
      password !== confirmPassword
    ) {
      setClientError({
        ...clientError,
        confirmPasswordError: true,
        confirmPasswordHelperText: 'Passwords do not match',
      });
      window.setTimeout(() => {
        setClientError({
          ...clientError,
          confirmPasswordError: false,
          confirmPasswordHelperText: '',
        });
      }, 5000);
    } else {
      registerUser({
        firstname: firstName,
        lastname: lastName,
        email: email,
        password: password,
      });
    }
  };

  // react: return jsx
  return (
    <dev>
      <br />
      <Grid container>
        <Grid item xs={1} className={gridLeft}></Grid>
        <Grid item xs={3} className={gridLeft}>
          <img
            className={img}
            src={Sara_register}
            alt={'Walk-through register'}
          />
        </Grid>
      </Grid>
      <Container maxWidth='sm' className={container}>
        <Card className={card} raised>
          <Typography variant='h5' align='left' gutterBottom>
            Register an account
          </Typography>
          <form
            noValidate
            autoComplete='off'
            spellCheck='false'
            onSubmit={onSubmitHandlerForm}
          >
            <TextField
              id='firstname'
              name='firstName'
              type='text'
              label='First Name'
              placeholder='Enter your first name...'
              fullWidth
              error={firstNameError}
              helperText={firstNameHelperText}
              className={textField}
              value={firstName}
              onChange={onChangeHandlerInput}
            />
            <TextField
              id='lastname'
              name='lastName'
              type='text'
              label='Last Name'
              placeholder='Enter your last name...'
              fullWidth
              error={lastNameError}
              helperText={lastNameHelperText}
              className={textField}
              value={lastName}
              onChange={onChangeHandlerInput}
            />
            <TextField
              id='email'
              name='email'
              type='email'
              label='Email Address'
              placeholder='Enter your email address...'
              fullWidth
              error={emailError}
              helperText={emailHelperText}
              className={textField}
              value={email}
              onChange={onChangeHandlerInput}
            />
            <TextField
              id='password'
              name='password'
              type='password'
              autoComplete='current-password'
              label='Password'
              placeholder='Enter your password...'
              fullWidth
              error={passwordError}
              helperText={passwordHelperText}
              className={textField}
              value={password}
              onChange={onChangeHandlerInput}
            />
            <TextField
              id='confirmPassword'
              name='confirmPassword'
              type='password'
              autoComplete='current-password'
              label='Confirm Password'
              placeholder='Confirm your password...'
              fullWidth
              error={confirmPasswordError}
              helperText={confirmPasswordHelperText}
              className={textField}
              value={confirmPassword}
              onChange={onChangeHandlerInput}
            />
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              size='large'
              className={button}
              fullWidth
            >
              Create account
            </Button>
            <Typography color='error'>
              <small>{serverError}</small>
            </Typography>
            <small>
              Already have an account? Log in <Link to='/login'>here</Link>
            </small>
          </form>
        </Card>
      </Container>
    </dev>
  );
};

export default withStyles(styles)(Register);
